


.admin-panel__body{
    padding: 10px;
    max-width: 1200px;
    margin: auto;
    margin-top: 80px;
    margin-bottom: 40px;
}



.admin-panel__order{
    border-radius: 5px;
    border: 1px solid #c5c5c5;
    padding: 16px 8px;
    margin-bottom: 20px;
    font-size: 20px;
    width: 100%;
    cursor: pointer;
    background-color: #fff;
    transition: all 0.3s linear;
    display: grid;
    grid-template-columns: 0.16fr 0.16fr 0.20fr 0.16fr 0.16fr 0.16fr;
    
        @media(any-hover: hover){&:hover{
        transition: all 0.3s linear;
        background-color: #c5c5c5;
    }}

    .order-status{
            //@at-root: 17px;
            padding: 0 5px;
            height: 100%;
            margin-top: auto;
            margin-bottom: auto;
    }
    

    .timestamp{
            border-right: 3px solid #c5c5c5;
            padding-right: 5px;
            display: flex;
            align-items: center;
            justify-content: center;     
            height: 100%;
    }

    .fw500{
        font-weight: 700;
        color: #454547;
    }
}

.admin-panel__order.hover-none{
    cursor: default;
    @media(any-hover: hover){&:hover{
        background-color: #fff;
    }}

}

.payment-status{
    border-right: 3px solid #c5c5c5;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; 
    height: 100%;
    img{
        width: 60px;
        height: 60px;
    }
}

.admin-order-totalPrice{
    font-weight: 700;
    font-size: 24px;
    color: #454547;
    display: flex;
    align-items: center;
    justify-content: center; 
    border-right: 3px solid #c5c5c5;
    height: 100%;
}


.mx{
    margin-top: auto;
    margin-bottom: auto;
}

.br-none{
    border-right: none;
}

.payment-status{
    padding-left: 5px;
    padding-right: 5px;
}


.product__more-button.none{
    display: none !important;
}

.admin-main-navigation{
        margin: 10px 0;
    a{
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 18px;
        display: block;
    }
}

.admin-title{
    padding-top: 5px;
    font-size: 18px;
}