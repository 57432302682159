
@import './../../Sass/mixin.scss';

.luxure__net{
		
		padding-top: 11.7%;
		padding-bottom: 11.7%;
		position: relative;
		overflow: hidden;
		margin-bottom: 8px;



		.main{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		h6{
			@include adaptiv-value(font-size,34,15,1);
			
		}
}

.luxure__net-body{
	z-index: 3;
	position: relative;
	color: #fff;
}

.bedroom__button-wr{
	margin-right: 3%;
}

.luxure__net-body{
	@media (max-width: 767px){
		flex-direction: column;
		padding: 0px !important;
	}
	
}

.luxure__net{
	@media (max-width: 767px){
		padding: 0;
		padding-bottom: 40px;
	}
	
	h6{
		
	}
}

.luxury__title{
		max-width: 50%;
		line-height: 130%;

		@media (max-width: 992px){
			max-width: 70%;
		}
		
		
		@media (max-width: 767px){
			max-width: 100%;
			background-color: rgba(71, 71, 69, 0.4);
			padding: 12px;
			margin-bottom: 80px;
			text-align: center;
		}
		


}


.flooring__net{
	@media (max-width: 767px){
		grid-template-columns: 1fr;
	}
	
}

.m10{
	margin-bottom: 10px;	
}

