@import './../../Sass/mixin.scss';

.callMeIcon {
  position: fixed;
  background-color: rgba(118, 168, 88, 0.87);
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: content-box;
  outline: 10px solid rgba(205, 205, 205, 0.17);
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 8000;

  transition: transform 0.5s ease-in-out;

  .container {
    width: 50px;
    height: 50px;
    position: relative;
    perspective: 1500px;
    display: flex;
    justify-content: center;

    align-items: center;

    .back, .front {
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      text-align: center;
      color: white;
      transition: all 0.58s linear;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.rotate .front {
      transform: rotateY(180deg);
    }

    &.rotate .back {
      transform: rotateY(0deg);
    }

    &.shake {
      animation: tilt-shaking 0.15s infinite;
    }

    .back {
      transform: rotateY(-180deg);
    }

  }

  img {
    width: 42px;
    height: 42px;
  }


  .text {

    color: white;
    font-size: 16px;

  }

}

@keyframes tilt-shaking {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.modal__wr.calculate .modal__body {
  .callMeForm {
    .spanLabel {
      width: 120px;
      flex-shrink: 0;
    }

    padding-top: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    // padding: 40px;
    @media (max-width: 700px) {
      padding: 3px;
    }

    button {
      margin-top: 10px;
    }

    label {

      margin: 10px 0;
      display: flex;
      align-items: center;
      column-gap: 20px;
      font-size: 23px;
      font-weight: bolder;
      color: #303030;
      @media (max-width: 700px) {
        margin: 3px;
        font-size: 18px;
        column-gap: 8px;
      }

      input {
        max-width: none !important;;
        height: 50px !important;
        width: 100% !important;
        border: 1px solid #454547 !important;

        &.callMeInvalid {


          border: 2px solid #ac0000 !important;
          border-radius: 5px !important;
        }

        font-size: 20px;
      }

      .react-tel-input .flag-dropdown {
        border-top: 1px solid #454547 !important;
        border-bottom: 1px solid #454547 !important;
        border-left: 1px solid #454547 !important;

      }

      .resetMargin {
        margin: 5px 0 5px 0px !important;
      }
    }


  }

  .callMe_options {
    display: flex;
    width: 100%;
    flex-direction: column;
    row-gap: 15px;
    background: #F7F7F7;
    padding: 30px;
    margin-top: 10px;


    button {
      width: 100%;
      height: 50px;
      color: #ffffff;
      text-shadow: 0px 0px 2px #373737;
      background-color: #6f9aa9;
      border: 1px solid #267e9b;
      border-radius: 5px;
      /* border-radius: 0; */

      @include adaptiv-value(font-size, 23, 13, 1);
      text-transform: uppercase;
      transition: 300ms all;


      &:hover {
        @include adaptiv-value(font-size, 23.4, 13.2, 1);
        background-color: #638e9e;
      }
    }
  }

}

.callMeTitle {
  padding-top: 60px !important;
  text-align: left !important;
  font-size: 30px !important;

  img {
    width: 25px;
    position: relative;
    top: 2px;
  }
}

.callMeLogo {
  position: absolute;
  opacity: 0.5;
  left: 20px;
  top: 10px;
  width: 50px;
}

.callMeDescription {
  padding-bottom: 10px;
}

.callMeInvalid {


  border: 1px solid red !important;
  border-radius: 7px !important;
}

