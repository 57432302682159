@import '../../Sass/mixin.scss';


.contacts {
	@include adaptiv-value(margin-top, 60, 10, 1);
	@include adaptiv-value(margin-bottom, 60, 40, 1);
}


.contacts__net {
	display: flex;
	gap: 16px;
	padding-top: 10px;
	@media (max-width: 767px){
		flex-direction: column;

	}
	
}

.contacts__net-map {
	flex: 0 1 50%;
	position: relative;
	padding-top: 36%;

	@media (max-width: 767px){
		padding-top: 0%;
		height: 300px;
	}
	
	iframe {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		@media (max-width: 767px){
			position: relative;
			height: 300px;
		}
	}

}

.contacts__net-text {
	flex: 0 1 50%;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	@media (max-width: 767px){
		margin-top: 10px;
	}
	

	section{
		text-align: center;
		max-width: 60%;
		margin-bottom: 16px;
		@include adaptiv-value(font-size,22,18,1);
		line-height: 130%;

		span{
			@include adaptiv-value(font-size,20,16,1);
		}
		b{
			margin-right: 5px;
		}

		@media (max-width: 480px){
			max-width: 100%;

		}
		
	}

	
		button{
			background-color: #454547;
			color: #fff;
			margin-top: 16px;
			font-weight: 500;
			border-radius: 5px;
			font-size: 16px;
			transition: all 0.3s linear;
			@include adaptiv-value(padding-top,16,4,1);
			@include adaptiv-value(padding-bottom,16,4,1);
			@include adaptiv-value(padding-left,40,16,1);
			@include adaptiv-value(padding-right,40,16,1);
			@media(any-hover: hover){&:hover{
				transition: all 0.3s linear;
				background-color: #33332e;
			}}

			@media (max-width: 420px){
				padding-top: 10px;
				padding-bottom: 10px;
				width: 100%;
			}
	}
}