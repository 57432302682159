
* {
  padding: 0;
  margin: 0;
  border: 0;

}

.product__img-wr > .arrow__previous,
.product__img-wr > .arrow__next {
  @media (max-width: 760px) {
    display: none !important;
  }
}

*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus, :active {
  outline: none;
}

a:focus, a:active {
  outline: none;
}

nav, footer, header, aside {
  display: block;
}

html, body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 16px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input, button, textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a, a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}


input::-webkit-input-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}

input::-moz-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}

input:-moz-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}

input:-ms-input-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}

input:focus::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

input:focus::-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

input:focus:-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

input:focus:-ms-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

textarea {
  resize: none;
}


///botstrap


ul, li {
  padding: 0;
  margin: 0;
}


img {
  max-width: 100%;
}





