@import '../../Sass/mixin.scss';

.bedroom__net {
  margin-bottom: 40px;

  @media (max-width: 600px) {
    margin-bottom: 30px;
  }

  .sofas__item-link {
    @include adaptiv-value(font-size, 32, 16, 1);

  }


}


.bedroom__net-first {
  display: grid;
  grid-template-columns: 0.37fr 0.63fr;
  gap: 8px;
  margin-bottom: 8px;
  @media (max-width: 992px) {
    grid-template-columns: 1fr;

    .i1 {
      order: 1;
    }
    .i2 {
      order: 2;
    }
    .i3 {
      order: 3;
    }
    .i4 {
      order: 4;
    }
  }


}

.bedroom__net-second {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
}

.bedroom__net-item {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 600px) {
    padding-top: 0px;
  }


  h6 {
    position: relative;
    @include adaptiv-value(font-size, 34, 15, 1);
    text-decoration: none;
    color: #fff;
    text-align: center;
    max-width: 70%;
    line-height: 130%;
    text-transform: uppercase;
    margin-bottom: 80px;
    //text-shadow: 0px 1px 1px black;
    // text-shadow: 1px 2px 1px #323232, 0px -1px 1px #5c5c5c;
    text-shadow: 0 0 3px #000;
    @media (max-width: 600px) {
      max-width: 100%;
      padding: 12px;
      width: 100%;
      background-color: rgba(71, 71, 69, 0.4);

    }

  }

  img.main {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

}

.bedroom__configurator-btn {
  position: relative;
  //box-shadow: 0px 0px 20px 2px inset #737373c4;
  background-color: #6d6d6d47;
  box-shadow: inset 0 0 20px 2px hsl(0deg 1.29% 29.55% / 86%);
  text-shadow: 0px 1px 1px black;
  z-index: 2;
  border: 1px solid #fff;
  text-transform: uppercase;
  @include adaptiv-value(font-size, 20, 16, 1);
  color: #fff;
  display: inline-block;
  @include adaptiv-value(border-radius, 15, 6, 1);
  @include adaptiv-value(padding-top, 24, 6, 1);
  @include adaptiv-value(padding-bottom, 24, 6, 1);
  @include adaptiv-value(padding-left, 36, 6, 1);
  @include adaptiv-value(padding-right, 36, 20, 1);
  font-weight: 500;
}

.bedroom__configurator-arrow {
  position: absolute;
  top: 50%;
  right: 50%;
  z-index: 2000;
  transform: translate(240%, -50%);
  @include adaptiv-value(width, 80, 40, 1);

  @media (max-width: 992px) {
    transform: translate(260%, -50%);
  }
  @media (max-width: 600px) {
    transform: translate(270%, -50%);
  }


}

.bedroom__button-wr {
  position: relative;
}
