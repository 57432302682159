.mobileNav {

  .mobileOpen {
    position: fixed;
    background: white;
    top: 110px;

    left: 0;
    width: 100%;
    z-index: 99;
  }
}


.mobileNavFixed {
  position: fixed;
  padding-top: 5px;
  padding-bottom: 5px;
  top: 74px;
  z-index: 9999;
  background: white;
  width: 100%;
  left: 0;

  button span {

    font-size: 16px !important;
  }

  @media (max-width: 480px) {
    top: 60px;
  }
  @media (min-width: 600px) {
    position: fixed;

    width: 100%;

    position: fixed;
    top: 65px;
    z-index: 10000000;
    background: white;
    width: 100%;
    left: 0;
    padding-bottom: 10px;

  }
}

