

.flooring__net{
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 8px;
	margin-bottom: 40px;

	@media (max-width: 600px) {
		margin-bottom: 30px;
	}
}