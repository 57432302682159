@import '../../Sass/mixin.scss';

.modules__empty {
  margin-top: 10px;
  font-weight: 600;
}

.modules {
  margin-top: 10px;
}

.subModulTitle {
  padding-bottom: 3px;
  padding-left: 3px;
}

.modules__wrapper {
  width: 100%;
  border: 1px solid rgba(71, 71, 69, 0.4);
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 3px 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 420px) {
    padding: 10px 3px;
  }

}

.modules__wrapper {
  transition: all 0.3s linear;

  .modal__wr {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;

    .modal__body {
      max-width: 600px;

      li {

        padding-bottom: 10px;
        padding-left: 20px;
        position: relative;
        line-height: 140%;
        font-weight: 500;

        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 7px;

          left: 6px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #454547;

        }

      }
    }

  }


  .product__quantity-counter {
    padding-left: 24px;

    img {
      width: 18px;
    }

    @media (max-width: 420px) {
      padding-left: 0px;
    }

  }

  .modules__price {
    flex: 1 1 auto;
    text-align: center;
  }

  @media (max-width: 420px) {
    .quantity__arrow {
      display: block;
    }
  }

  .modules__price {
    padding-left: 5px;
    padding-right: 5px;
  }

  @media (max-width: 420px) {
    .modules__img-wr {
      width: 50px;
      height: 50px;
    }
  }

}

.modules__wrapper.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.modules__img-wr {
  position: relative;
  @include adaptiv-value(width, 80, 30, 1);
  @include adaptiv-value(height, 80, 30, 1);
}

.modules__characteristics {
  button {
    text-decoration: underline;

    @media(any-hover: hover) {
      &:hover {
        text-decoration: none;
      }
    }

  }
}


.modules__img-wr {
  position: relative;
  cursor: zoom-in;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url('../../../public/img/configurator__hover.png') center center no-repeat rgba(0, 0, 0, 0.767);
    z-index: 2;
    background-size: auto 70%;
    opacity: 0;
    transition: all 0.3s linear;

  }

  @media(any-hover: hover) {
    &:hover {
      &:after {
        opacity: 1;
        transition: all 0.3s linear;

      }
    }
  }

}

.quantity__arrow {
  pointer-events: all !important;
}


.modules__wrapper {
  h4 {
    margin-bottom: 24px !important;
  }
}

.modules__characteristics {
  button {
    text-align: center !important;
  }
}


.modules__characteristics {
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 420px) {
    align-items: center;
  }
}
