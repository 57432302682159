@import '../../Sass/mixin.scss';

.collection__bread-crumbs {
  margin-bottom: 16px;
}

.collectionElement__wr {
  width: 100%;
  position: relative;
  padding-top: 60%;

  & > img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  h6 {
    z-index: 2;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    left: 0;
    text-align: center;
    //  background: rgba(71, 71, 69, 0.6);
    color: #fff;
    font-weight: 700;
    // text-decoration: underline;
    transition: all 0.3s linear;
    text-shadow: 0 0 3px black;

    @media(any-hover: hover) {
      &:hover {
        transition: all 0.3s linear;
        //  background: #474745;
      }
    }

  }
}

.collection-wrapper {

  @include adaptiv-value(margin-bottom, 60, 20, 1);

  .catalog__net {

    @media (max-width: 767px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }

  }
}
