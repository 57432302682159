

.submodules{
    h6{
        margin-bottom: 26px;
        text-align: left;
        font-size: 20px;
    }

 .modal__wr .modal__body{
    max-width: 70%;
    
    @media (max-width: 992px) {
        max-width: 100%;
    }
 }

 .modal__body{
    cursor: default;
 }
}


.submodules__net-item{

         border: 1px solid #c5c5c5;   

         img{
            cursor: pointer;
            position: relative;
            z-index: 1;
         }


        
        p{
            margin-bottom: 10px;
            padding: 5px;
            font-size: 18px;
        }
}

.submodules__net{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    @media (max-width: 420px) {
        grid-template-columns: 1fr;
    }
}

.submodules__item-img{
        position: relative;

        &:after{          
            content: '';
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            margin: 5px;
            transition: all 0.4s linear;
            width: 45px;
            height: 45px;
            background:  url('../../../public/img/active__submodules.svg') center center no-repeat;
            background-size: 100%;

            @media (max-width: 767px) {
                width: 32px;
                height: 32px;
            }
        }
}

.submodules__item-img.active{
        &:after{
            opacity: 1;
            transition: all 0.4s linear;
        }
}