@import './../../Sass/mixin.scss';

.header__list-body {
  width: 100%;
  display: flex;
  justify-content: center;

  .menuLinksContainers {

    display: flex;
    justify-content: center;

    width: min-content;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    @include adaptiv-value(padding, 16, 8, 1);
    gap: 8px;

    a {
      width: min-content;
      display: block;

    }

    .header__list-container {

    }

    @media (max-width: 770px) {
      display: none;
    }
  }
}

.header__list-body a {

  background: #F4F4F4;
  @include adaptiv-value(padding-top, 16, 4, 1);
  @include adaptiv-value(padding-bottom, 16, 4, 1);
  @include adaptiv-value(padding-left, 24, 12, 1);
  @include adaptiv-value(padding-right, 24, 12, 1);

  &:first-letter {
    text-transform: uppercase;
  }

}

.header__list-body {
  position: absolute;
  //top: 0%;
  left: 0%;
  opacity: 0;
  z-index: 1001;
  transition: all 0.3s linear;
  pointer-events: none;
  //visibility: hidden;
  transform: translateY(-25px);
}

.header__list-body.active {
  opacity: 1;
  transition: all 0.3s linear;
  pointer-events: all;
  visibility: visible;
  transform: translateY(-20px);
}

.header__list {
  position: relative;
}

.header__list li {
  color: #474745;
  cursor: pointer;
}

.header__list-title {
  line-height: 120%;
  @include adaptiv-value(font-size, 20, 12, 1);

}

.header__list-title img {
  @include adaptiv-value(width, 18, 12, 1);
  transition: all 0.3s linear;

}

.header__list-title img.rotate {
  transform: rotate(180deg);

}

.header__list-title a {
  margin-right: 8px;
  text-align: center;
}

.header__list-body a {
  transition: all 0.3s linear;
  white-space: nowrap;
  text-align: center;
  width: 100%;
  flex: 1 1 auto;
  @include adaptiv-value(font-size, 16, 13, 1);
  @media(any-hover: hover) {
    &:hover {
      background: #dddddd;
    }
  }

}


.header__list-mobile-title {
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
}
