@import './../../Sass/mixin.scss';

.sofas__bread-crumbs {
  gap: 8px;
  @media (max-width: 992px) {
    display: none !important;
  }
  margin-bottom: 10px;

  a {
    color: black !important;
  }

  button {
    color: black !important;

  }
}

.sofas {

  .mobile__controls {
    padding-left: 0 !important;
  }
}

// .catalog__title{
// 	margin-top: 10px;
// }

.sofas__net {

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px 8px;
  margin-bottom: 40px;
  @media (max-width: 767px) {
    gap: 2px;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    margin-bottom: 30px;
  }

}

.sofas__net-item {
  position: relative;
  padding-top: 44.5vh;
  width: 100%;
  height: 100%;

  @media (max-width: 1300px) {
    padding-top: 48vh;
  }

  @media (max-width: 992px) {
    padding-top: 57%;
  }


  img.main {

    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


.sofas__item-link {
  width: 100%;
  position: absolute;
  top: 50%;
  z-index: 2;
  transition: all 0.3s linear;
  transform: translateY(-50%);
  text-align: center;
  left: 0;
  height: auto;

  //background: rgba(71, 71, 69, 0.4);
  //text-shadow: 0 0 3px black;
  // background: rgba(115, 115, 115, 0.4);;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  //font-weight: 700;
  font-weight: 400;
  width: 100%;
  @include adaptiv-value(padding-top, 40, 10, 1);
  @include adaptiv-value(padding-bottom, 40, 10, 1);
  @include adaptiv-value(font-size, 32, 20, 1);
  //text-decoration: underline;
  letter-spacing: 0.05em;
  transition: all 0.3s linear;
  text-transform: uppercase;
  //text-shadow: 1px 2px 1px #323232, 0px -1px 1px #5c5c5c;
  text-shadow: 0 0 3px #000;
  @media(any-hover: hover) {
    &:hover {
      // text-decoration: underline;
      transition: all 0.3s linear;
      //   background: #474745;
      //background: rgba(124, 124, 121, 0.67);

    }
  }
}
