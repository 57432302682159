@import './../../Sass/mixin.scss';


.search__net {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 20px 12px;

	@media (max-width: 600px) {
		grid-template-columns: 1fr 1fr;
	}

	margin-bottom: 60px;

}

.search h2 {
	margin-bottom: 40px;
	margin-top: 20px;
	font-weight: 400;
	@include adaptiv-value(font-size, 36, 18, 1);

	@media (max-width: 600px) {
		margin-bottom: 10px;
	}
}

.search__empty {
	height: 300px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	line-height: 130%;


	img{
		@include adaptiv-value(width,120,60,1);
	
		transition: all 0.3s linear;
	}

	p{
		margin-top: 5px;
		margin-bottom: 10px;
		font-size: 18px;
	}

	a{
		text-decoration: underline;
		font-weight: 500;
		font-size: 20px;
	}
}