

.catalog-orders-button , .admin-exit{
        width: 60px;
        height: 60px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        right: 24px;
        background-color: #c5c5c5;
        padding: 10px;
        transition: all 0.3s linear;
        cursor: pointer;

        @media(any-hover: hover){&:hover{
            background-color: #8f8f91;
            transition: all 0.3s linear;
            
        }}
        
}

.catalog-orders-button {
    bottom: 100px;
}

.admin-exit{
    bottom: 24px;
}

.edit-icon{
border-radius: 0 !important;
width: 24px !important;
height: 24px !important;
}


.editPaidType{
    display: flex;
    align-items: center;
    justify-content: center;

    .modal__body{
        max-width: 767px;

        button{
            margin-top: 40px;
        }
    }


    .types{
            li{
                padding-top: 10px;
                padding-bottom: 10px;
                font-size: 18px;
                position: relative;
                padding-left: 27px;
                cursor: pointer;
            }

            li.active{
                &:after{
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translate(0, -50%);
                    display: block;
                    width: 24px;
                    height: 24px;
                    background: url('../../../public/img/active__submodules.svg') center center / cover no-repeat;
                }
                
            }

            input{
                border: 2px solid #454547;
                border-radius: 5px;
                width: 70px;
                font-size: 18px;
                height: 30px;
                padding: 5px;
                margin-left: 16px;
            }
            span{
                font-size: 20px;
                margin-left: 5px;
                font-weight: 700;
                color: #454547;
            }

            
    }
}   


.part-paid-number{
        border: 2px solid #454547;
        margin-left: 8px;
        border-radius: 5px;
        padding: 3px 6px;
}


.input-edit-comment{
    border: 2px solid #454547;
    border-radius: 5px;
    margin-top: 20px; 
    width: 100%;
    height: 200px;
    padding: 10px;
    font-size: 18px;
}