@import '../../Sass/mixin.scss';


.admin-panel-wr{
    line-height: 130%;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  


    input{
        margin-bottom: 20px;
        padding: 5px;
        background-color: #fff;
        font-size: 18px;
        border: 1px solid #454547;
        border-radius: 5px;
        width: 100%;
        padding-left: 10px;
        height: 40px;

        &::placeholder{
            opacity: 0.7;
        }
    }

    .invalid-s{
        color: red;
        letter-spacing: 1px;
        font-size: 12px;
        display: block;
        font-weight: 600;
        opacity: 0;
        transition: all 0.3s linear;
        
    }

    .invalid-s.active{
            opacity: 1;
            transition: all 0.3s linear;
    }




    .admin-panel{

        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        max-width: 360px;
        width: 100%;
        margin-left: 10px;
        margin-right: 10px;
        @include adaptiv-value(margin-top,80,40,1);
        @include adaptiv-value(margin-bottom,80,40,1);    

        
input:-webkit-autofill {
    -webkit-box-shadow: 0px 0px 0px 50px rgba(255, 255, 255, 1) inset; /* Цвет фона */
    -moz-box-shadow: 0px 0px 0px 50px rgba(255, 255, 255, 1) inset;
    box-shadow: 0px 0px 0px 50px rgba(255, 255, 255, 1) inset; 
    -webkit-text-fill-color: #9196A1 !important; /* цвет текста */
    color: #9196A1 !important; /* цвет текста */
    transition: none;
}

    }

    .button-s{
        width: 100%;
        min-height: 40px;
        letter-spacing: 1px;
    }
}

.password__wr{
    position: relative;

    img{
        cursor: pointer;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-75%);
        width: 24px;
        cursor: pointer;
        transition: all 0.3s linear;
        
        @media(any-hover: hover){&:hover{
            transform: translateY(-75%) scale(1.1);
        }}
        

    }
}