@import '../../Sass/mixin';

.header__language-wr {

  display: flex;
  column-gap: 18px;
}

.header__language-wr_mobile {
  width: min-content;
  margin-top: 20px;
  display: flex;
  column-gap: 18px;

}

header {
  @include adaptiv-value(padding-left, 40, 10, 1);
  @include adaptiv-value(padding-right, 40, 10, 1);
  width: 100%;
  position: relative;


  @media (max-width: 767px) {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 10000;
    margin-bottom: 30px;
  }

}

.header__first {
  @include adaptiv-value(padding-top, 40, 10, 1);
  @include adaptiv-value(padding-bottom, 40, 10, 1);


  .full {
    @include adaptiv-value(width, 400, 100, 1);
  }

  .header__search-wr {
    @include adaptiv-value(width, 650, 100, 1);
  }


  .short {
    @include adaptiv-value(width, 90, 30, 1);
  }

  @media (max-width: 767px) {
    display: none !important;
  }

}


.header__search-wr {
  background: #F5F5F5;
  border-radius: 15px;
  @include adaptiv-value(padding, 20, 4, 1);

  padding-left: 0 !important;

  input {
    @include adaptiv-value(padding-left, 20, 4, 1);

  }
}

.header__search {
  input {
    width: 100%;
    height: 100%;
    background-color: transparent;
    padding-right: 16px;
    font-size: 16px;
    @include adaptiv-value(padding-top, 16, 4, 1);
    @include adaptiv-value(padding-bottom, 16, 4, 1);
  }

  input::placeholder {
    color: rgba(57, 87, 115, 0.6);
  }
}


.search__button {
  background: #474745;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 500;
  padding: 20px 50px;
  color: #fff;
  display: inline-block;
  @include adaptiv-value(padding-top, 16, 4, 1);
  @include adaptiv-value(padding-bottom, 16, 4, 1);
  @include adaptiv-value(padding-left, 40, 10, 1);
  @include adaptiv-value(padding-right, 40, 10, 1);
  transition: all 0.3s linear;

  @media(any-hover: hover) {
    &:hover {
      background-color: #33332e;
    }
  }


  @media (max-width: 1300px) {
    font-size: 16px;
  }

}

.header__language {
  font-size: 14px;
  font-weight: 500;
}

.header__language-title {
  min-width: 75px;
  color: #fff;
  background-color: #474745;
  border-radius: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  transition: all 0.3s linear;

  @media(any-hover: hover) {
    &:hover {
      background-color: #33332e;
    }
  }


  p {
    text-transform: uppercase;
    margin-right: 6px;
  }

  img {
    margin: auto;
  }
}


.header__language-body {
  background-color: #474745;
  width: 100%;
  text-transform: uppercase;
  color: #fff;
  font-weight: 500;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  transition: all 0.3s linear;
  border-radius: 0 0 10px 10px;

  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transform: translateY(-8px);
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 1000;

  span {
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    color: #fff;
    width: 100%;

    @media(any-hover: hover) {
      &:hover {
        background-color: #33332e;
      }
    }
  }

}


.header__language {
  position: relative;
}


.header__language.active .header__language-title {
  border-radius: 10px 10px 0 0;
}

.header__language.active .header__language-body {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
  transform: translateY(0px);
}

.header__language img {
  transition: all 0.3s linear;
  @include adaptiv-value(width, 18, 12, 1);
}

.header__language.active img {
  transform: rotate(180deg);

}

.header__nav {
  @include adaptiv-value(gap, 24, 16, 1);

  img {
    @include adaptiv-value(width, 36, 16, 1);
    cursor: pointer;
    transition: all 0.3s linear;


    @media(any-hover: hover) {
      &:hover {
        transform: scale(1.12);
        transition: all 0.3s linear;

      }
    }

  }
}

.header__drawerContainer {
  position: relative;

  .header__drawer_counter {
    position: absolute;
    width: 19px;
    height: 19px;
    font-size: 9px;
    color: #ffffff;
    border: 1px solid white;
    background: #474745;
    border-radius: 50%;
    right: -9px;
    top: -15px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 1px;
    @media (max-width: 967px) {
      width: 16px;
      height: 16px;
      font-size: 11px;
      top: -12px;
    }
  }
}

.header__list {
  @include adaptiv-value(gap, 45, 12, 1);
  margin-left: 6%;
  margin-right: 3%;
}


@media (max-width: 767px) {
  .header__second {
    display: none !important;
  }
}

.header__second {
  padding-bottom: 24px;
}

.header__first .short {
  margin-left: 100%;
}

.header__mobile-drawer {
  flex: 0 0 18px;
  width: 18px;
  min-width: 19px;
}

.header__mobile-logo {
  flex: 1 1 auto;
  padding-left: 10%;
  padding-right: 12%;
  width: 100%;

  max-width: 480px;
  margin: auto;

}

.header__burger {
  flex: 0 0 25px;
  height: 18px;
  position: relative;


  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: #000;
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.3s linear;

  }

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: #000;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: all 0.3s linear;

  }

  span:after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: #000;
    position: absolute;
    left: 0;
    top: calc(50% - 1px);
    transition: all 0.3s linear;

  }

}


.header__burger.active {
  &:after {
    transform: rotate(45deg);
    top: calc(50% - 1px);
  }

  &:before {
    transform: rotate(-45deg);
    bottom: calc(50% - 1px);
  }

  span:after {
    transform: translateX(-10px);
    opacity: 0;
  }
}


.header__mobile {
  padding-top: 25px;
  padding-bottom: 25px;

  @media (max-width: 360px) {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  @media (min-width: 768px) {
    display: none !important;
  }
}

.burger__body {
  @media (min-width: 768px) {
    display: none !important;
  }
  @include adaptiv-value(padding-left, 40, 10, 1);
  @include adaptiv-value(padding-right, 40, 10, 1);
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  width: 100%;
  height: 100vh;
  padding-top: 15px;
  min-height: 100%;
  z-index: 13800;
  transform: translateX(-100%);
  transition: all 0.4s linear;

}

.burger__body.active {
  transform: translateX(0%);
}

.burger__body-content {
  border-top: 1px solid rgba(71, 71, 69, 0.3);
  width: 100%;
}

.header__list-mobile-title {
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(71, 71, 69, 0.3);
}


.header__list-mobile-body {
  display: none;
}

.header__list-mobile-body.active {
  display: block;
}

.header__list-mobile-item {
  padding-top: 6px;
  padding-bottom: 6px;
  border-bottom: 1px solid rgba(71, 71, 69, 0.15);

  span {
    font-weight: 700;
    margin-right: 5px;
  }

  p {
    font-size: 13px;
    width: 100%;

  }
}

.header__list-mobile-title {
  p {
    font-weight: 700;
    font-size: 15px;
  }

  img {
    width: 12px;
  }
}

.header__list-mobile-title img {
  transition: all 0.3s linear;
}

.header__list-mobile-title img.rotate {
  transition: all 0.3s linear;
  transform: rotate(45deg);

}

.header__mobile-buttons {
  gap: 16px;
  max-width: 360px;
  margin-top: 36px;
  margin-bottom: 36px;
  width: 100%;
}

.header__mobile-social-networks {
  gap: 16px;

  a {
    width: 24px;
  }
}

.header__mobile-btn {
  background: #474745;
  border-radius: 15px;
  font-size: 17px;
  color: #fff;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
}

.header__list-title {
  cursor: pointer;
}


.favorite__button-mobile {
  background-color: transparent;
  font-weight: 700;
}
