@import './../Sass/mixin.scss';

img {
  overflow-clip-margin: unset;
}

body,
html {
  //overflow-x: hidden;
  scroll-behavior: smooth;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: 'Arial';
}


.wrrapper {
  min-height: 100vh !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  //	overflow-x: hidden;
}

img {
  width: 100%;
}

main {
  flex: 1 1 auto;
  width: 100%;
}

li {
  list-style: none;
}


span {
  display: inline-block;
}

a {
  display: inline-block;
  color: #000;

  @media(any-hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }

}

button {
  background-color: transparent;
}

.content {
  @include adaptiv-value(padding-left, 40, 10, 1);
  @include adaptiv-value(padding-right, 40, 10, 1);
}


main {
  @media (max-width: 767px) {
    padding-top: 80px;
  }

  @media (max-width: 400px) {
    padding-top: 70px;
  }
  @media (max-width: 350px) {
    padding-top: 50px;
  }

}

.d-none {
  display: none !important;
}

.sofas__item-link {
  padding-left: 5px;
  line-height: 130%;
  padding-right: 5px;
}

.swiper-skeleton {
  background: #c5c5c5;
  width: 100%;
  display: block;
  position: relative;
  z-index: 9999;
  height: 75vh !important;


  @media (max-width: 992px) {
    height: 100%;
    padding-bottom: 65%;
  }

  @media (max-width: 600px) {
    padding-bottom: 60%;
  }

}


.skeleton__wr {
  width: 100%;
  padding: 5px;
}

.skeleton__wr span {
  width: 100%;
  height: 300px;
  display: block;
  border-radius: 5px;
}


.skeleton-categories,
.skeleton-categories-h,
.skeleton-main {
  padding: 2px;
}

.skeleton-categories span,
.skeleton-categories-h span,
.skeleton-main span {
  width: 100%;

  border-radius: 5px;
  background-color: #c5c5c5;

  height: 44.5vh;


  @media (max-width: 1300px) {
    height: 48vh;
  }

  @media (max-width: 992px) {
    padding-top: 57% !important;
    height: 100%;

  }
}


.skeleton-categories-h span {
  height: 66vh;

  @media (max-width: 992px) {
    padding-top: 60%;
    height: 100%;
  }
}


.skeleton-main span {
  background-color: #fff;
  height: 600px;
}

.ScrollbarsCustom-Track,
.ScrollbarsCustom-TrackY {
  width: 6px !important;
}

.modal__wr {
  position: fixed;
  width: 100%;
  height: 100%;

  overflow-x: hidden;
  overflow-y: auto;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  @include adaptiv-value(padding-top, 80, 60, 1);
  @include adaptiv-value(padding-bottom, 80, 40, 1);

  opacity: 0;
  pointer-events: none;
  transition: all 0.5s linear;
  z-index: 9999999999;
  @include adaptiv-value(padding-bottom, 80, 40, 1);
}

.modal__wr.active {
  transition: all 0.5s linear;
  opacity: 1;
  pointer-events: all;
}

.modal__body {
  width: 100%;
  max-width: 80%;
  margin: auto;
  background-color: #fff;
  color: #060606;
  border-radius: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  transform: perspective(600px) translate(0px, -400%) rotateX(110deg);
  transition: all 0.8s linear;
  @include adaptiv-value(padding, 24, 8, 1);
  @include adaptiv-value(padding-top, 40, 16, 1);
  @include adaptiv-value(padding-bottom, 60, 16, 1);


  @media (max-width: 992px) {
    max-width: 95%;
  }


}

.modal__wr.active .modal__body {

  transform: perspective(600px) translate(0px, 0%) rotateX(0deg);
  transition: all 0.5s linear;

}

.footer__skeleton {
  width: 100%;
  margin-top: 20px;
}

.footer__skeleton span {
  display: block;
  width: 100%;
  height: 150px;
  border-radius: 5px;
  background-color: #c5c5c5;
}

.skeleton-header {
  width: 70px;
  height: 16px;
}

.skeleton-header span {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: #c5c5c5;
}

.sofas__item-link,
.categories__item-link,
.card__img-wr img,
.luxure__net-body {
  z-index: 1000;
}


.bedroom__button-wr,
h6 {
  z-index: 1000;
  position: relative;
}


.lb-container img {
  width: auto;
}

.lb-canvas .lb-img {
  max-height: 90vh !important;
  max-width: 90vw !important;
}


.skeleton-app {

  width: 100%;

  position: fixed;
  height: 750vh;
  display: flex;

  img {

    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    animation: rotatePreloader 3s linear infinite normal 0s forwards;

  }
}

@keyframes rotatePreloader {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.collectionElement__wr {
  .skeleton-load-img {
    border-radius: 0px;

  }
}

.z1 {
  z-index: 0;
}

.gap8 {
  gap: 8px;
}

.cost-delivery-single-information {

  margin: 60px;

  section {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    font-size: 20px;
    margin-bottom: 20px;


    p {
      font-weight: 600;
      color: rgba(0, 0, 0, 0.715);
    }

    span {
      font-weight: 400;
    }

  }

  img {
    margin-left: 8px;
    cursor: pointer;
  }

}

.admin-panel-consultation__net-wr {
  margin: 60px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h4 {
    margin-bottom: 20px;
    font-size: 28px;
    text-align: center;
    width: 100%;
  }
}

.admin-panel-consultation__net {

  width: fit-content;
  display: inline-grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center center;
  gap: 0 20px;

}

.admin-panel-consultation__item {
  margin-bottom: 20px;
  border: 1px solid #454547;
  border-radius: 5px;
  padding: 10px;
  max-width: 320px;
  font-size: 18px;
  font-weight: 500;
  width: 100%;

  .edit-icon {
    cursor: pointer;
  }

  section {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-start;
    margin-bottom: 16px;

    span.active {
      position: relative;

      &:after {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        background-color: green;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(150%, -50%);
      }

    }
  }
}


.swiper-wrapper.active {
  transition-duration: 0ms;
  transform: translate3d(0px, 0px, 0px) !important;
}

.bedroom__configurator-arrow {
  right: -5%;
  transform: translate(50%, -50%);
}
