
	$maxWidth: 1920;
	$container: 1920;


@mixin adaptiv-value($property,$pcSize, $mobSize, $size) {
 $addSize: $pcSize - $mobSize;

@if $size==1 {
	#{$property} : $pcSize + px;
	@media (max-width: #{$container + px}){
		#{$property} : calc(#{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth}));
	}

} @else {
	#{$property} : calc(#{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth}));
}
}
