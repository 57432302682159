.yarl__slide_captions_container {
  background: rgba(0, 0, 0, 1);
  left: 0;
  display: flex;
  padding: 0 15px;
  align-items: center;
  position: absolute;
  right: 0;
  -webkit-transform: 0;
  top: 0;
  color: white;
  font-size: 20px;
  height: 50px;

}

.yarl__slide_image {
  width: initial;
}


.lightBoxBackTop {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;

  z-index: 10000;
  pointer-events: none;
  height: 50px;

  background-color: black;
}

* {
  padding: 0;
  margin: 0;
  border: 0;
  $yarnBack: rgba(0, 0, 0, 0.46);
  --yarl__portal_zindex: 99999;
  --yarl__container_background_color: rgba(0, 0, 0, .8);
  --yarl__thumbnails_container_background_color: rgba(0, 0, 0, .8);;
  --yarl__thumbnails_thumbnail_background: #292424;
  --yarl__color_backdrop: transparent;
  --yarl__color_button: white;
  --yarl__thumbnails_thumbnail_padding: 0;
  --yarl__color_button_active: #d8d8d8;
  --yarl__button_padding: 0 8px 0 8px;
  --yarl__carousel_padding_px: 10px;
  @media (max-width: 500px) {
    --yarl__button_padding: 0 2px 0 2px;
  }

  //--yarl__navigation_button_padding: 35px;
}

.yarl__toolbar {
  background-color: black;
}

.yarl__navigation_next, .yarl__navigation_prev {
  svg {
    height: 58px !important;
    width: 58px !important;
    @media (max-width: 800px) {
      height: 35px !important;
      width: 35px !important;
    }
  }
}

.yarl__slide_image {
  max-height: calc(100% - 15px) !important;

  padding-top: 48px !important;
}
