@import './../../Sass/mixin.scss';

.filterContainerOpen {
  // transition: 100ms;
  height: 0;

  &.open {
    height: 246px;

  }

  @media (min-width: 601px) {
    display: none;
  }
}


.filterCatalogParent {
  position: relative;
  display: flex;
  flex-direction: column;

  button {
    span {
      color: black;
    }
  }
}

.catalog {
  overflow-x: hidden;

  .catalogPadding {

    @include adaptiv-value(padding-left, 40, 10, 1);
    @include adaptiv-value(padding-right, 40, 10, 1);
  }

  transition: all 0.3s linear;


  @media (max-width: 767px) {
    display: grid;
  }


}

.navContainer {
  position: relative;
}

.mobileHidde {
  @media (max-width: 600px) {
    display: none;
  }
}

.realNav {
  &.primaryNav {
    padding-top: 0 !important;
    //  position: static !important;
    top: -10px !important;
    position: relative !important;
    z-index: 10 !important;
    padding-bottom: 5px !important;
    margin-bottom: 0 !important;
  }

  width: 100%;
  position: absolute;
  // height: 55px;
  transition: 200ms padding, 970ms border;

  @media (max-width: 600px) {


    // border-bottom: 1px solid #606060;
    padding-top: 10px;
    padding-bottom: 10px;
    position: fixed;
    top: 70px;
    z-index: 10000;
    width: 100%;
    background: white;
    left: 0;
  }

  &.changeToFixed {
    //   border-bottom: 1px solid #606060;
    padding-top: 10px;
    padding-bottom: 10px;
    position: fixed;

    top: 0px;

    z-index: 10000;
    width: 100%;
    background: white;
    left: 0;
  }

  @media (max-width: 750px) {
    &.changeToFixed {
      top: 75px;
    }
  }
}

.fakeNav {
  pointer-events: none;
  //height: 55px;
}

.filterMobile {
  z-index: 9980;

  @media (max-width: 600px) {
    background: white;
    position: fixed;
    &.mobileOpen {
      overflow: visible;

      .catalog__filters {
        background: white;
        top: 100px;
        position: fixed;
        width: 100%;
      }
    }
    /*
      position: absolute;
      top: 35px;
      // transition: left 300ms;
      height: 0;
      overflow: hidden;
      background: white;
      width: 100%;
      z-index: 8000;

      & > div {
        margin-bottom: 0;
        padding-bottom: 0;
        padding-left: 10px;
        padding-right: 10px;
        color: black;
      }
      &.mobileOpen {
        overflow: visible;
        left: 0;
        top: 60px;
        //border-bottom: 1px solid #676767;
        .catalog__filters {
          background: white;

        }



      }
  */
  }
  @media (min-width: 601px) {
    &.mobileHidde {
      //  display: none;
    }
  }
}

.catalog__title {
  @include adaptiv-value(margin-bottom, 36, 24, 1);
  @include adaptiv-value(font-size, 36, 18, 1);
  font-weight: 400;
  text-transform: uppercase;
  @include adaptiv-value(margin-top, 36, 16, 1);

  @media (max-width: 767px) {
    order: 2;
  }

}


.catalog__filters {

  flex-wrap: wrap;

  @media (max-width: 767px) {
    display: block !important;
  }

}

.catalog_filter-spacer {
  width: 100%;
  @include adaptiv-value(margin-bottom, 40, 20, 1);
}

.catalog__net {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px 12px;

  &.rows2 {
    grid-template-columns: 1fr 1fr;
  }

  &.rows3 {
    grid-template-columns: 1fr 1fr 1fr;
  }

  &.rows4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  &.rows5 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    @media (max-width: 600px) {
      .card h4 {
        font-size: calc(6.667px + .41667vw) !important;
      }
      .card h5 {
        font-size: calc(8.667px + .41667vw) !important;

      }
    }

  }

  @media (max-width: 767px) {
    order: 3;

  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr 1fr;
    gap: 16px 6px;
  }
}


.catalog__net span {
  display: inline-flex;

}

.catalog__pagination {
  @include adaptiv-value(margin-bottom, 80, 40, 1);

  gap: 16px;
  flex-wrap: wrap;
  padding-left: 10px;
  padding-right: 10px;

  @media (max-width: 767px) {
    order: 4;
  }

  @media (max-width: 600px) {
    gap: 8px;
  }

}


.catalog__pagination-wr {
  @include adaptiv-value(margin-top, 100, 40, 1);

  @media (max-width: 767px) {
    order: 5;
  }

}

.product__more-button {

  background-color: #292929;
  height: 36px;
  @include adaptiv-value(padding-left, 24, 16, 1);
  @include adaptiv-value(padding-right, 24, 16, 1);
  @include adaptiv-value(font-size, 18, 16, 1);
  color: #fff;
  transition: all 0.3s linear;
  margin-bottom: 20px;
  text-transform: uppercase;
  border-radius: 5px;


  @media(any-hover: hover) {
    &:hover {
      background-color: #1d1b1b;
    }
  }


}

.catalog__pagination-item {
  background-color: rgba(41, 41, 41, 0.2);
  border-radius: 5px;
  color: #fff;
  padding: 8px 13px;
  font-weight: 500;
  @include adaptiv-value(font-size, 24, 12, 1);
  transition: all 0.3s linear;

  @media (max-width: 480px) {
    padding: 5px 8px;
  }


  @media(any-hover: hover) {
    &:hover {
      background-color: rgba(41, 41, 41, 0.4);
    }
  }

}


.catalog__pagination-item.active {
  background-color: #292929;

  @media(any-hover: hover) {
    &:hover {
      background-color: #292929;
    }
  }

}


.catalog__filter-title {
  @include adaptiv-value(font-size, 20, 16, 1);
  @include adaptiv-value(padding-left, 24, 12, 1);
  @include adaptiv-value(padding-right, 24, 12, 1);
  padding-bottom: 8px;
  padding-top: 8px;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(41, 41, 41, 0.8);

  @media (max-width: 767px) {
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;

    span {
      width: 100%;
    }
  }

  span {
    margin-right: 8px;
  }

  img {
    width: 12px;
    padding-bottom: 2px;
  }


}

.catalog__filters {
  @include adaptiv-value(gap, 36, 16, 1);
  color: rgba(41, 41, 41, 0.8);

  @media (max-width: 767px) {
    order: 1;
  }

}

.catalog__filter-item {
  position: relative;

}

.catalog__filter-body {
  position: absolute !important;
  top: 120% !important;
  left: 0 !important;
  z-index: 99999991 !important;
  opacity: 0;
  pointer-events: none;
  transform: translateY(-20px);
  transition: all 0.3s linear;
  width: 260px !important;
  height: 300px !important;
  background-color: #fff;
  border-radius: 5px;
  padding-top: 16px !important;

  @media (max-width: 767px) {
    width: 100% !important;
    top: 101% !important;


  }


  li {
    margin-bottom: 16px;
    background-color: #F4F4F4;
    width: auto;
    position: relative;
    z-index: 99999991 !important;
    font-size: 16px;
    @include adaptiv-value(padding, 16, 10, 1);
    cursor: pointer;
    transition: all 0.3s linear;
    margin-left: 12px;
    margin-right: 8px;

    @media(any-hover: hover) {
      &:hover {
        background-color: #dddddd;
        transition: all 0.3s linear;

      }
    }

  }
}

.catalog__filter-body.active {
  opacity: 1;
  pointer-events: all;
  transform: translateY(0px);
  transition: all 0.3s linear;

}

.catalog__filter-title img.rotate {
  transform: rotate(180deg);
  transition: all 0.3s linear;
}


.catalog__filter-title img {
  transition: all 0.3s linear;
}

.categories.none,
.d-none {
  display: none !important;
}

.mobile__controls {
  @include adaptiv-value(padding-left, 40, 10, 1);
  margin-bottom: 20px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 24px;
    font-weight: 400;
    font-size: 16px;
    background-color: transparent;
    //  color: rgba(41, 41, 41, 0.8);
    padding-bottom: 4px;
    // border-bottom: 1px solid rgba(41, 41, 41, 0.8);
    // text-transform: uppercase;
    // letter-spacing: 1px;
    color: black;

    img {
      width: 14px;
      transition: all 0.3s linear;
      transform: rotate(180deg);
      //  position: relative;
      // top: 1px;


    }

    img.rotate {
      transform: rotate(0deg);
    }

    span {
      margin-right: 6px;
    }
  }
}

.custom-skeleton {
  background-color: #c5c5c5;
  width: 100%;
  height: 300px;
  border-radius: 5px;
  display: block;
  @media (max-width: 600px) {
    height: 200px;
  }

}

.product__more-button:disabled {
  opacity: 0.6;
  cursor: default;
}

.catalog__pagination-item {
  margin-left: 5px;
  margin-right: 5px;
}

.dots {
  cursor: default;

  @media(any-hover: hover) {
    &:hover {
      background-color: rgba(41, 41, 41, 0.2);
    }
  }

}

.catalog__filter-item.dis {
  cursor: none;
  transition: all 0.3s linear;
  opacity: 0.4;
  pointer-events: none;
}

.catalog__empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 250px;

  p {
    font-size: 18px;
  }

  img {
    @include adaptiv-value(width, 120, 60, 1);
  }
}
