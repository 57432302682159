@import './../../Sass/mixin.scss';

.drawer {
  position: fixed;
  top: 0%;
  right: 0;
  background-color: #fff;

  @include adaptiv-value(padding-top, 40, 20, 1);
  @include adaptiv-value(padding-bottom, 40, 20, 1);
  width: 400px;
  padding-left: 16px;
  padding-right: 16px;
  transition: all 0.3s linear;
  transform: translateX(100%);
  z-index: 15000;

  @media (max-width: 420px) {
    width: 100%;
  }


  h4 {
    @include adaptiv-value(margin-bottom, 36, 16, 1);
    font-weight: 400;
    @include adaptiv-value(font-size, 36, 20, 1);
  }


  .shoppingCart__main-characteristicts-net {
    font-size: 14px;
    margin-top: 5px;

    img {
      width: 16px;
      height: 16px;
      border: 1px solid #aeaeae;
    }
  }


  .drawer__item-configutations-body {

    padding-top: 0px;
    padding-bottom: 0px;

    .configutation-item {
      font-size: 14px;
      margin-bottom: 5px;
    }


    .configutation-item:first-child {
      margin-top: 7px;
    }
  }

  .drawer__item-configutations {
    margin-bottom: 5px;
    margin-top: 0px;

    h6 {
      font-size: 14px;
    }

  }


  .module__quantity, .module__price-SP {
    font-size: 9px;

  }

  .module__price-SP {
    padding: 3px;
  }

  .module__quantity {
    width: 15px;
    height: 15px;
  }

}

.drawer.active {
  transform: translateX(0%);
}


.drawer__wr {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(41, 41, 41, 0.8);
  z-index: 14000;
  transition: all 0.3s linear;

  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}


.drawer__wr.active {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.drawer__body {
  height: 55vh !important;
  max-height: 400px;
  overflow: auto;

}

.drawer__details {
  @include adaptiv-value(margin-top, 32, 16, 1);

}

.drawer__total {
  @include adaptiv-value(font-size, 20, 16, 1);
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  padding-top: 14px;
  padding-bottom: 14px;
  @include adaptiv-value(margin-bottom, 32, 16, 1);
}

.drawer__shopping-cart {
  text-transform: uppercase;
  background-color: #292929;
  text-align: center;
  font-weight: 400;
  @include adaptiv-value(font-size, 24, 14, 1);
  margin-bottom: 8px;
  width: 100%;
  color: #fff;
  padding-top: 12px;
  padding-bottom: 12px;
  transition: all 0.3s linear;

  @media(any-hover: hover) {
    &:hover {
      background-color: #33332e;
    }
  }

}

.drawer__checkout {
  width: 100%;
  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px;
  text-transform: uppercase;
}

.drawer__close {
  width: 27px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}


.drawer__body-item-wr {
  margin-bottom: 15px;
}

.drawer__body-item {
  display: grid;
  grid-template-columns: 0.25fr 0.75fr;
  gap: 1%;
  padding-right: 14px;


  h5 {
    @include adaptiv-value(font-size, 20, 16, 1);
    font-weight: 400;
  }

  h6 {
    font-weight: 500;
    @include adaptiv-value(font-size, 20, 14, 1);
  }
}


.drawer__item-preview {
  padding-top: 100%;
  position: relative;

  .main {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.drawer__item-delete {
  border-radius: 50%;
  padding: 4px;
  @include adaptiv-value(width, 32, 20, 1);
  @include adaptiv-value(height, 32, 20, 1);
  cursor: pointer;
  background-color: #D9D9D9;
}

.drawer__nav {
  margin-bottom: 8px;
  margin-top: 8px;

  .drawer__quantity-wrapper {
    font-size: 14px;
  }
}

.drawer__empty {
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 18px;

  @media (max-width: 480px) {
    height: 200px;
  }


  button {
    font-size: 18px;
    text-decoration: underline;
    @media(any-hover: hover) {
      &:hover {
        text-decoration: none;
      }
    }

  }

  p {
    margin-bottom: 10px;
  }

  img {
    margin-bottom: 10px;
    @include adaptiv-value(width, 80, 60, 1);

  }
}

.drawer__promo {
  color: #fff;
  background-color: #292929;
  padding: 4px 8px;
  font-weight: 600;
  display: inline-block;
  transition: all 0.3s linear;
  border-radius: 5px;
  margin-bottom: 8px;
  font-size: 15px;
  animation: scaleAnim 5s linear infinite normal 0s forwards;
}

@keyframes scaleAnim {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(1.1);
  }
  40% {
    transform: scale(1);
  }
}


.drawer__quantity-counter {

  margin-left: 10px;
  font-size: 16px;
  font-weight: 700;
  align-items: center;
  color: #454547;

  .minus {
    transform: translateY(-2px);
  }

  .minus, .plus {
    padding: 0 6px;
    cursor: pointer;
    transition: all 0.3s linear;
  }
}

.drawer__item-details {

  display: flex;
  flex-direction: column;

  .drawer__nav {
    flex: 1 1 auto;
  }
}


.drawer__item-modules {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 4px;
  width: 100%;
  margin-top: 5px;

  @media (max-width: 380px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }


  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

  }
}

.drawer__item-modules-img {
  position: relative;
  border: 1px solid #c5c5c5;
  width: 100%;
  height: 100%;
  padding-top: 100%;

}

.drawer__item-modules-img .more-information {
  position: absolute !important;
  z-index: 2 !important;
  top: 7px !important;
  right: 7px !important;
  left: auto !important;
  width: 20px !important;
  height: 20px !important;
}

.drawer__body-item-wr {
  border-bottom: 1px solid #c5c5c5;
}


.drawer__item-configutations h6 {
  padding-top: 14px;
}
