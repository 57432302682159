

@import '../../Sass/mixin.scss';

.help{
	@include adaptiv-value(margin-top,60,10,1);
	@include adaptiv-value(margin-bottom,60,40,1);
	transition: all 0.3s linear;
	

	p, li, .accordion-content{
		line-height: 140%;
		@include adaptiv-value(font-size,20,16,1);	
	}
	.accordion-content{
		line-height: 130%;
		@include adaptiv-value(font-size,18,14,1);
	}

	section{
		transition: all 0.3s linear;
		border-bottom: 1px solid #c5c5c5;
		padding-top: 10px;
	 }


	li{
		margin-bottom: 10px;
	}

	h5{
		margin-top: 30px;
		margin-bottom: 30px;
		@include adaptiv-value(font-size,27,18,1);
		color: #454547;
		line-height: 120%;	
		font-weight: 700;
}
	
}

.help__p1{
	margin-bottom: 10px;
}

.help__questions-title{
	margin-top: 70px !important;
}

.accordion-content {
	padding: 5px 0 5px 0;
	max-height: 0;
	overflow: hidden;
	opacity: 0;
	transition: all 0.3s ease; /* Додайте анімацію до max-height */
 }
 
 .accordion-item.active .accordion-content {
	max-height: 500px;
	opacity: 1;
	padding: 5px 0 15px 0;
	transition: all 0.3s linear;
	
 }

 .accordion-item.active{
	img{
		transform: rotate(180deg);
		transition: all 0.3s linear;
		
	}
 }

.accordeon__title{
		gap: 20px;
		color: #454547;
		cursor: pointer;
		font-weight: 700;
		font-size: 16px;
		img{
			transition: all 0.3s linear;
			
			width: 20px;
		}
 }

 