

.orderInformation{

    margin: 24px 0;

    h3{    
        margin-bottom: 27px;
        font-weight: 400;
        font-size: 24px;
    }

    .redC{
        color: red;
        margin: 0px;
    }
    .yellowC{
        color: rgb(160, 160, 79) ;
        margin: 0px ;
    }
    .greenC{
        color: green ;
        margin: 0px 
    }




    .drawer__item-modules{
            grid-template-columns: repeat(6,1fr);
            margin-bottom: 36px;
            margin-top: -15px;
    }
    .more-information{
        cursor: pointer;
    }


   .drawer__item-modules-more-information{
        h3{
            font-size: 18px !important;
            margin-bottom: 5px !important;
        }
    }

    img{
        cursor: pointer;
    }
}

.orderInformation__form{

    max-width: 600px;


        input{
            border: 1px solid #454547;
            border-radius: 5px 0 0 5px;
            padding: 5px;
            width: 100%;
            height: 36px;
            font-size: 16px;

            &::placeholder{
                opacity: 0.7;
            }
        }

        button{
            color: #fff;
            background-color: #454547;
            display: block;
            height: 36px;
            padding-left: 10px;
            padding-right: 10px;
            border-radius: 0 5px 5px 0;
            transition: all 0.3s linear;
            font-size: 16px;


            @media(any-hover: hover){&:hover{
                background-color: #2c2c2c;
                transition: all 0.3s linear;
                
            }}
            
        }
}

.orderInformation__notApplied{
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        width: 64px;
    }
}


.orderInformation__net{
    margin-top: 40px;
    margin-bottom: 20px;
}

.orderInformation__net-item{

    section{
        display: flex;
        align-items: center;
        gap: 14px;   
        font-size: 20px; 
        margin-bottom: 18px;

        img{
            width: 32px;
            height: 32px;
            border-radius: 50%;
        }
    }
   
    span{
        
        font-weight: 700;
        color: #454547;
    }
}

.orderInformation__product{
    display: grid;
    grid-template-columns: 0.3fr 0.7fr;
    gap: 12px;
   
    border: 2px solid #c5c5c5;
    margin-bottom: 14px;
}

.orderInformation__product-text{
    padding: 5px;

    h4{
        font-weight: 400;
        font-size: 20px;
        line-height: 120%;
        margin-bottom: 20px;
    }
}

.orderInformation__product-img{
        
        position: relative;
        padding-top: 80%;

        img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;

        }
}

.color-wr, .material-wr{
        gap: 8px;
}