@import '../../Sass/mixin.scss';




.modal__body {
	width: 100%;

	h4 {
		@include adaptiv-value(font-size, 26, 16, 1);
		font-weight: 400;
		margin-bottom: 12px;
	}

	h5 {
		text-align: center;
		width: 100%;
		text-transform: uppercase;
		color: #474745;
		@include adaptiv-value(font-size, 30, 16, 1);
		line-height: 120%;
		@include adaptiv-value(margin-bottom, 50, 27, 1);


	}

	.modules__characteristics{
		button{
			@media (max-width: 992px){
				width: 100%;
			}
			
		}
	}


	.miniature{
	  width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      z-index: 2;
	  overflow: hidden;
	  border-radius: 5px;
	}

	.modules{
		margin-top: 0px;
		@include adaptiv-value(padding-right,24,8,1);
		
	}

}

.colors {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 8px;


	img {
		border-radius: 50%;
		@include adaptiv-value(width, 40, 26, 1);
		@include adaptiv-value(height, 40, 26, 1);
		cursor: pointer;
		transition: all 0.3s linear;
	}

	img.active {
		border: 2px solid #000;
		padding: 3px;
		transition: all 0.3s linear;
	}
}

.configurator__options {
	gap: 12px;

	span {
		@include adaptiv-value(font-size, 20, 16, 1);
		border-radius: 5px;
		border: 1px solid #000;
		padding: 2px 4px;
		background-color: transparent;
		transition: all 0.3s linear;
		cursor: pointer;

	}

	span.active {
		background-color: #474745;
		color: #fff;
		transition: all 0.3s linear;

	}
}

.configurator__button {
	background: #474745;
	text-transform: uppercase;
	font-size: 18px;
	font-weight: 500;
	padding: 20px 50px;
	color: #fff;
	display: inline-block;
	@include adaptiv-value(padding-top, 24, 10, 1);
	@include adaptiv-value(padding-bottom, 24, 10, 1);
	@include adaptiv-value(padding-left, 40, 10, 1);
	@include adaptiv-value(padding-right, 40, 10, 1);
	width: 100%;
	max-width: 320px;
	transition: all 0.3s linear;

	@media(any-hover: hover) {
		&:hover {
			background-color: #33332e;
		}
	}


	@media (max-width: 1300px) {
		font-size: 16px;
	}
}


.configurator__modal-body,
.configurator__modal-img-wr {
	flex: 0 0 50%;
	width: 100%;
	min-width: 1px;
	min-height: 70vh;

	@media (max-width: 767px){
		min-height: auto;
	}
	
}



.configurator__modal-body {
	.wrapper {
		max-width: 70%;
	}

}


.configurator__modal-wr {
	@include adaptiv-value(margin-bottom, 38, 20, 1);

	.description {
		margin-top: 5px;
		font-weight: 500;
		line-height: 130%;
	}

	@media (max-width: 767px) {
		flex-direction: column-reverse;
		gap: 24px;
	}

}

.configurator__modal-img {

	.swiper-pagination-bullet-active{
		background: #454547;
	}

	.swiper{
	
		height: 55vh;
	}

	.swiper-slide{
	
		position: relative;
		height: 100%;
		width: 100%;
	}


	.swiper-button-next, .swiper-button-prev{
		width: 26px;
		height: 26px;
		
		color: #fff;
		background-color: #474745;
		border-radius: 50%;

		&:after{
			display: none;
		}

		&:before{
			content: '';
			width: 11px;
			height: 11px;
			border-top: none;
			border-bottom: 2px solid #fff;
			border-left: 2px solid #fff;
			border-right: none;
			border-radius: 2px;
			position: absolute;
			top: 50%;
			left: 50%;
			
		}

		
		

	}

	.swiper-button-next{
		&:before{
			transform: translate(-60%,-45%) rotate(220deg);
		}
	}

	.swiper-button-prev{
		&:before{
			transform: translate(-40%,-45%) rotate(45deg);
		}
	}
	
	
	//padding-top: 100%;
	width: 100%;
	height: 100%;

	img {

		position: absolute;
		top: 0;
		left: 0;
		object-fit: cover;
		width: 100%;
		height: 100%;
	}


}

.configurator__modal-close {
	display: block;
	background-color: #fff;
	cursor: pointer;
	@include adaptiv-value(width, 40, 24, 1);
	@include adaptiv-value(height, 40, 24, 1);
	position: absolute;
	border-radius: 50%;
	top: 0;
	right: 0;
	transform: translate(120%, 0%);

	@media (max-width: 992px) {
		transform: translate(0%, -120%);
	}

	&:after {
		content: '';
		display: block;
		width: 70%;
		border-radius: 5px;
		position: absolute;
		top: 50%;
		left: 50%;
		height: 2px;
		background-color: #454547;
		transform: translate(-50%,-50%) rotate(45deg);
	}

	&:before {
		content: '';
		display: block;
		width: 70%;
		border-radius: 5px;
		position: absolute;
		top: 50%;
		left: 50%;
		height: 2px;
		background-color: #454547;
		transform: translate(-50%,-50%) rotate(-45deg);
	}

}

.modalConfigurator__quantity{
    gap: 10px;
}

.modalConfigurator__quantity-counter{
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
        button{
           @include adaptiv-value(font-size, 26, 16, 1);  

        }
}


.modalConfigurator__quantity-minus{
        margin-top: -2px;
}